import React from "react"
import { Container, Row } from "reactstrap"
import { CalcFinancing } from "../../elements"

function Financing() {
  return (
    <>
      <Container className="bg-black" fluid>
        <Container fluid className="">
          <Row className="justify-content-center py-3">
            <h1 className="font-weight-bold text-white text-center">
              Simula tu inversión
            </h1>
          </Row>
        </Container>
      </Container>
      <CalcFinancing />
    </>
  )
}

export default Financing
