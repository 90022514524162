import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Generate,
  Requirements,
  Financing,
  Econo4All
} from "../components/sections/page_4"
const FourthPage = () => (
  <Layout>
    <SEO title="Invierte" />
    <Generate />
    <Econo4All/>
    <Financing />
    <Requirements />
    <div id="contacto" />
  </Layout>
)

export default FourthPage
