import React, { useRef } from "react"
import { Container, Row, Col } from "reactstrap"
import { useVisible } from "../../../hooks/index"

import ident from "../../../images/page_4/icon/identi.svg"
import id_card from "../../../images/page_4/icon/id-card.svg"
import payment from "../../../images/landing/remodela/payment.svg"
import newspaper from "../../../images/page_4/icon/newspaper.svg"
import rightimg from "../../../images/page_4/rightimg.png"

import "../../../styles/requierements.css"

const requierements = [
  {
    icon: ident,
    text: "Nacionalidad mexicana.",
  },
  {
    icon: id_card,
    text: "Mayor de 18 años con RFC.",
  },
  {
    icon: payment,
    text: "Contar con cuenta bancaria.",
  },
  {
    icon: newspaper,
    text: "Justificar los fondos que se aportarán.",
  },
]

function Requirements() {
  const ref = useRef()
  const visible = useVisible(ref)
  return (
    <>
      <Container className="d-flex justify-content-center" fluid>
        <Row className="align-items-center justify-content-center">
          <Col
            xs={12}
            md={6}>
            <div className={`animated ${visible ? "fadeInLeft" : "fadeInRight"}`}>
              <div ref={ref}>
                <h2 className="mt-5 font-weight-bold margin-requ aliging">
                  Requisitos
                </h2>
                {requierements.slice(0, 4).map(({ icon, text }, i) => (
                  <Row className="mt-4 margin-requ">
                    <Col md={1} sm={1} className="aliging ">
                      <img src={icon} alt="" className="icon_requ" />
                    </Col>
                    <Col md={11} sm={11}>
                      <h5 className="text_requ aliging">{text}</h5>
                    </Col>
                  </Row>
                ))}
              </div>  
            </div>

            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1Q6SaUun5hFYECciFrwmscEJW4TeWQwmM/view"
                className=""
              >
                <h5 className="sub_align aliging text-orange mt-5 font-weight-bold mb-5 pb-5 ">
                  <u>
                    Consulta la Ganancia Anual Total (GAT) y la Tasa Efectiva
                    Anual (TEA) aquí.
                  </u>
                </h5>
              </a>
            </div>
          </Col>

          <Col xs={12} md={6} className="requierements_img">
            <img className="img-fluid" src={rightimg} alt="imagen" />
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Requirements
