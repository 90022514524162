import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

import "../../../styles/generate.css"
import ScrollAnimation from "react-animate-on-scroll"

function Generate() {
  return (
    <>
      <Container
        className="bg-generate d-flex flex-column justify-content-center"
        fluid>
        <Row className="mt-5 justify-content-center align-items-center text-center">
          <Col className="">
            <h1 className="text-yellow">
              ¿Generas rendimientos del 10%, o menos, al año?
            </h1>
            <h4 className="cstm_text_sub text-white">
              Aquí puedes ganar hasta
            </h4>
            <h1 className="custom_text_generate my-5 text-white font-weight-bold">
              18%
            </h1>
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
              <Button href="#contacto" color="primary" className="">
                {" "}
                Contáctanos
              </Button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>

      <Container
        className="bg-white d-flex flex-column justify-content-center  my-5 "
        fluid
      >
        <Row className="align-items-center px-2 text-center">
          <Col md={1}>
          </Col>
          <Col sm={2} md={2} className="my-md-0 my-2">
            <h4 className="text-orange font-weight-bold">
              Monto mínimo
            </h4>
            <h5 className="d-md-none font-weight-bold">
              Puedes invertir desde $10,000.00
            </h5>
          </Col>
          <Col sm={2} md={2} className="my-md-0 my-2">
            <h4 className="text-orange font-weight-bold">
              Tasa fija
            </h4>
            <h5 className="d-md-none font-weight-bold">
              Que se mantiene durante el plazo de inversión
            </h5>
          </Col>
          <Col sm={2} md={2} className="my-md-0 my-2">
            <h4 className="text-orange font-weight-bold">
              Sin comisiones
            </h4>
            <h5 className="d-md-none font-weight-bold">
              No cobramos ningún tipo de comisiones
            </h5>
          </Col>
          <Col sm={2} md={2} className="my-md-0 my-2">
            <h4 className="text-orange font-weight-bold">
              Pagos mesuales
            </h4>
            <h5 className="d-md-none font-weight-bold">
              Pago de capital e intereses con frecuencia mensual
            </h5>
          </Col>
          <Col sm={2} md={2} className="my-md-0 my-2">
            <h4 className="text-orange font-weight-bold">
              No pases tu vida esperando
            </h4>
            <h5 className="d-md-none font-weight-bold">
              Genera rendimientos desde el primer año
            </h5>
          </Col>
          <Col md={1}>
          </Col>
        </Row>
        
        <Row className="d-md-flex d-none px-2 text-center">
          <Col md={1}>
          </Col>
          <Col sm={12} md={2}>
            <h5 className="font-weight-bold">
              Puedes invertir desde $10,000.00
            </h5>
          </Col>
          <Col className="" sm={12} md={2}>
            <h5 className="font-weight-bold">
              Que se mantiene durante el plazo de inversión
            </h5>
          </Col>
          <Col className="" sm={12} md={2}>            
            <h5 className="font-weight-bold">
              No cobramos ningún tipo de comisiones
            </h5>
          </Col>
          <Col className="" sm={12} md={2}>
            <h5 className="font-weight-bold">
              Pago de capital e intereses con frecuencia mensual
            </h5>
          </Col>
          <Col className="" sm={12} md={2}>
            <h5 className="font-weight-bold">
              Genera rendimientos desde el primer año
            </h5>
          </Col>
          <Col md={1}>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Generate
