import { useState, useEffect } from "react"
import { debounce } from "../utils"
const DEBOUNCE_TIME = 300

export function useVisible(theRef, defVal) {
  const [visible, setVisible] = useState(defVal ? defVal : false)
  const checkInView = debounce(() => {
    const place = theRef.current.getBoundingClientRect()
    if (place) {
      if (place.top >= 0 && place.bottom <= window.innerHeight) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  }, DEBOUNCE_TIME)
  useEffect(() => {
    window.addEventListener("scroll", checkInView)
    return () => window.removeEventListener("scroll", checkInView)
    //eslint-disable-next-line
  }, [])
  return visible
}
