import React from "react"
import { Container, Row, Col, Table } from "reactstrap"

import "../../../styles/investment.css"

import monto from "../../../images/page_4/invest/monto.png"
import nopagos from "../../../images/page_4/invest/nopagos.png"

const tableinvest = [
  {
    montoinv: "$100,000.00",
    porcentaje: "12.00%",
    monto: "$12,000.00",
    trimestral: "$28,480.00",
  },
  {
    montoinv: "$200,000.00",
    porcentaje: "13.50%",
    monto: "$27,000.00",
    trimestral: "$57,830.00",
  },
  {
    montoinv: "$300,000.00",
    porcentaje: "15.00%",
    monto: "$45,000.00",
    trimestral: "$88,050.00",
  },
]

const tableplan = [
  {
    num: "1",
    pagocap: "$75,000.00",
    pagoint: "$11,250.00",
    iva: "$1,800.00",
    trim: "$88,050.00",
  },
  {
    num: "2",
    pagocap: "$75,000.00",
    pagoint: "$11,250.00",
    iva: "$1,800.00",
    trim: "$88,050.00",
  },
  {
    num: "3",
    pagocap: "$75,000.00",
    pagoint: "$11,250.00",
    iva: "$1,800.00",
    trim: "$88,050.00",
  },
  {
    num: "4",
    pagocap: "$75,000.00",
    pagoint: "$11,250.00",
    iva: "$1,800.00",
    trim: "$88,050.00",
  },
]

const tabletot = [
  {
    num: "0",
    pagocap: "$300,000.00",
    pagoint: "$45,000.00",
    iva: "$7,200.00",
    trim: "$352,200.00",
  },
]

function Generate() {
  return (
    <Container className="bg-black cont_100" fluid>
      <Row className="text-center">
        <Col>
          <h2 className="text-white font-weight-bold my-5">
            Un ejemplo de tu inversión en plazo de un año
          </h2>
          <h5 className="text-white font-weight-bold my-5">
            Rendimiento generado al final del plazo
          </h5>
        </Col>
      </Row>

      <Row className="d-flex flex-direction-column justify-content-center">
        <img src={monto} alt="" className="disp_phone img-fluid" />
        <Table className="bg-black text-light w-50 border-top-0 table-responsive-sm table_diss_phone">
          <thead>
            <tr className="table-borderless">
              <th className="color_secondary_text text-center align-middle">
                <h5> Monto invertido</h5>
              </th>
              <th className=" text-center align-middle">
                <h5>Porcentaje</h5>
              </th>
              <th className="color_secondary_text text-center align-middle">
                <h5> Rendimiento</h5>
              </th>
              <th className="text-center align-middle">
                <h5>Pago trimestral con IVA</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableinvest.map(
              ({ montoinv, porcentaje, monto, trimestral }, i) => (
                <tr key={i}>
                  <th className="color_secondary_text text-center align-middle">
                    <h3> {montoinv}</h3>
                  </th>
                  <td className=" text-center align-middle">
                    <h3>{porcentaje}</h3>
                  </td>
                  <td className="color_secondary_text text-center align-middle">
                    <h3>{monto}</h3>
                  </td>
                  <td className=" text-center align-middle">
                    <h3>{trimestral}</h3>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Row>

      <Row>
        <Col className="text-center">
          <h2 className="text-white font-weight-bold my-5">
            Plan de pagos, a un año, si inviertes{" "}
            <font color="#E4D72B">$300,000.00</font>
          </h2>
        </Col>
      </Row>

      <Row className="d-flex flex-direction-column justify-content-center">
        <img src={nopagos} alt="" className="disp_phone img-fluid" />
        <Table className="bg-black text-light table_width border-top-0 table-responsive-sm table_diss_phone">
          <thead>
            <tr className="table-borderless">
              <th className="color_secondary_text text-center align-middle">
                <h5> No. de pago</h5>
              </th>
              <th className=" text-center align-middle">
                <h5>Pago de capital</h5>
              </th>
              <th className="color_secondary_text text-center align-middle">
                <h5> Pago de intereses</h5>
              </th>
              <th className="text-center align-middle">
                <h5>IVA intereses</h5>
              </th>
              <th className="text-center align-middle">
                <h5>Pago Trimestral</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableplan.map(({ num, pagocap, pagoint, iva, trim }, i) => (
              <tr key={i}>
                <th className="color_secondary_text text-center align-middle">
                  <h3> {num}</h3>
                </th>
                <td className=" text-center align-middle">
                  <h3>{pagocap}</h3>
                </td>
                <td className="color_secondary_text text-center align-middle">
                  <h3>{pagoint}</h3>
                </td>
                <td className=" text-center align-middle">
                  <h3>{iva}</h3>
                </td>
                <td className=" text-center align-middle">
                  <h3>{trim}</h3>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>

      <Row className="d-flex  justify-content-center ">
        <Table className="bg-black text-light tabletot_width border-top-0 table-responsive-sm table_diss_phone">
          <tbody className="">
            {tabletot.map(({ num, pagocap, pagoint, iva, trim }, i) => (
              <tr className="table-borderless justify-content-center text-center mb-3">
                <th className="font-weight-bold text-white ">
                  <p className="investment_border_white">{pagocap}</p>
                </th>
                <th className="font-weight-bold text-yellow">
                  <p className="investment_border_yellow">{pagoint}</p>
                </th>
                <th className="font-weight-bold text-white">
                  <p className="investment_border_white">{iva}</p>
                </th>
                <th className="font-weight-bold text-yellow">
                  <p className="investment_border_yellow">{trim}</p>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  )
}
export default Generate
