import React from "react"
import { Container, Row, Col} from "reactstrap"

import "../../../styles/generate.css"

function Econo4All() {
    return (
    <>
    <Container className="bg-black d-flex flex-column justify-content-center py-5" fluid>
        <Row className="justify-content-center">
            <h1 className="font-weight-bold text-white text-center">
                Existen ventajas económicas para todos
            </h1>
        </Row>
    </Container>
    
    <Container className="d-flex flex-column justify-content-center" fluid>
        <Row className="my-5">
            <Col md={3}></Col>
            <Col md={6}>
                <h5 className="text-center">
                    Alfa Ferre es una empresa de capital privado que actualmente financia proyectos de remodelación de bienes inmuebles y también busca impulsar el crecimiento de pequeñas empresas dedicadas principalmente a:
                </h5>
            </Col>
            <Col md={3}></Col>
        </Row>
        <Row className="d-flex justify-content-center my-md-5">
            <Col md={1}></Col>
            <Col md={3} className="align-items-center"> 
                <div className="h-100 py-5 bg-secondary rounded-pill">
                    <h5 className="px-4 text-white text-center">
                        La comercialización de herramientas relacionadas con la industria de la construcción.
                    </h5>
                </div>
            </Col>
            <Col md={3} className="align-items-center">
                <div className="h-100 py-5 bg-secondary rounded-pill ">
                    <h5 className="mt-md-3 px-5 text-white text-center ">
                        Alquiler de equipo para levantar, mover y acomodar materiales.
                    </h5>
                </div>
            </Col>
            <Col md={3} className="align-items-center">
                <div className="h-100 py-5 bg-secondary rounded-pill">
                    <h5 className="mt-md-3 px-5 text-white text-center">
                        Alquiler de equipo para el comercio y los servicios.
                    </h5>
                </div>
            </Col>
            <Col md={1}></Col>
        </Row>
        <Row className="my-5">
            <Col md={2}></Col>
            <Col md={4} >
                <h1 className="text-center font-weight-bold text-orange">
                    Clientes
                </h1>
                <p className="my-md-5 pr-md-5">
                    ● Los clientes que soliciten un financiamiento deben disponer de un bien mueble en México, libre de reporte de robo, además de la correspondiente documentación de identificación, para poner en garantía, con un aforo de, al menos, 2:1 sobre el monto solicitado. El monto es autorizado en función del valor comercial del activo en garantía. <br/><br/>
                    ● Además del contrato correspondiente se genera un pagaré por el monto total del pago frente a Alfa Ferre que es firmado por el cliente y un avalista.
                </p>
            </Col>
            <Col md={4}>
                <h1 className="text-center font-weight-bold text-orange">
                    Inversionistas
                </h1>
                <p className="my-md-5 pr-md-5">
                    ● Alfa Ferre ofrece a los inversionistas 18% (dieciocho por ciento) de rendimiento anual que se transfiere mensualmente a la cuenta bancaria del inversionista, es decir, un proporcional de 1.5% (uno punto cinco por ciento) fijo al mes. <br/><br/>
                    ● El plazo de inversión es por 12 meses y el capital no puede retirarse antes de cumplir este plazo. <br/><br/>
                    ● El monto mínimo de inversión es de $10,000.00 MXN. <br/><br/>
                    ● Puedes invertir hasta cinco millones de pesos bajo el mismo plazo de inversión (12 meses). Si tu inversión es mayor, se evaluará el plazo dependiendo de la cantidad total a invertir.
                </p>
            </Col>
            <Col md={2}></Col>
        </Row>
    </Container>
    </>
    )
}
export default Econo4All
